@media print {
  @page {
    margin: 0.5cm;
  }

  body {
    background-color: white;
    font-size: 10pt;
  }

  .MuiContainer-root {
    padding: 0 !important;
    max-width: none !important;
  }

  .MuiGrid-root {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .MuiCard-root {
    box-shadow: none !important;
    border: 1px solid #ddd;
  }

  .MuiCardContent-root {
    padding: 8px !important;
  }

  .MuiTypography-root {
    margin-bottom: 4px !important;
  }

  .MuiButton-root {
    display: none !important;
  }

  .MuiGrid-item {
    padding: 4px !important;
  }

  h4 {
    font-size: 14pt;
    margin-bottom: 8px !important;
  }

  h6 {
    font-size: 12pt;
    margin-bottom: 6px !important;
  }
}
